import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from './langs/en';
import de from './langs/de';

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: process.env.VUE_APP_DEFAULT_LOCALE,
    fallbackLocale: process.env.VUE_APP_FALLBACK_LOCALE,
    messages: {
        en: en,
        de: de
    }
})

export default i18n;