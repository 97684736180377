const strings = {
    "faq": "FAQ",
    "finished": "Erledigt",
    "in_progress": "In Bearbeitung",
    "logout": "Abmelden",
    "login": "Anmelden",
    "messages": {
        "bad_request": "Falsche Anfrage",
        "cannot_reach_server": "Server nicht erreichbar",
        "internal_server_error": "Interner Serverfehler",
        "not_found": "Nicht gefunden",
        "unknown_error": "Unbekannter Fehler",
        "validation_error": "Validierungsfehler"
    },
    "open": "Offen",
    "privacy": "Datenschutz",
    "status_equals": "Status: {status}",
    "tasks": "Aufgaben",
    "userTaskStatus": {
        "done": "Erledigt",
        "open": "Offen"
    }
}

export default strings;