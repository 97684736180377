import { Route } from '@gis/common';
import i18n from '../localization';
import Master from '../views/layouts/Master.vue';
import vuetify from "@/plugins/vuetify";

if (!process.env.VUE_APP_SUPPORTED_LOCALES) {
    throw new Error(".env config required: VUE_APP_SUPPORTED_LOCALES");
}

const SUPPORTED_LOCALES = process.env.VUE_APP_SUPPORTED_LOCALES.split(',') as Array<string>;

const rootLocalized = Route
    .withPath('/:lang', Master)
    .withName('root.localized')
    .onBeforeEnter((to, from, next) => {
        const lang = to.params.lang;
        if (!SUPPORTED_LOCALES.includes(lang)) {
            const toPath = to.path === '/' ? `${process.env.VUE_APP_START_URI || ''}` : to.path;
            return next(process.env.VUE_APP_DEFAULT_LOCALE + toPath);
        }
        else {
            if (to.path === `/${lang}`) {
                return next(`${process.env.VUE_APP_START_URI || ''}`);
            }
        }
        if (i18n.locale !== lang) {
            i18n.locale = lang;
        }
        vuetify.framework.lang.current = i18n.locale;
        return next();
    });

export default rootLocalized;