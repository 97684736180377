
import { Component, Prop, Vue } from "vue-property-decorator";
import { User } from "oidc-client";
import UserAvatar from '@/components/globals/UserAvatar.vue';
import { auth } from "@/utils/AuthService";

@Component({
    components: {
        UserAvatar
    }
})
export default class AppBar extends Vue {
    @Prop({ type: String, required: true })
    readonly title!: string;

    @Prop({ type: Object, required: false, default: null })
    readonly currentUser!: User;

    get logo(): string | null {
        if (process.env.VUE_APP_LOGO) {
            return process.env.VUE_APP_LOGO;
        }
        return null;
    }

    logout(): void {
        auth().logout();
    }
}
