
import { Component, Vue } from "vue-property-decorator";
import { User } from "oidc-client";
import { EventBus, EventBusMessage } from "@gis/common";
import { GlobalMessageHelper } from "@gis/common";
import { auth } from "./utils/AuthService";

@Component({ name: "App" })
export default class VueApp extends Vue {
    currentUser: User | null = null;

    mounted(): void {
        //Is anyone logged in yet?
        auth().getUser()
            .then((user) => {
                this.currentUser = user;
            })
            .catch((e) => {
                console.error(e);
            });

        EventBus.$on(EventBusMessage.UNAUTHORIZED, async () => {
            if (await auth().ensureUserIsLoggedIn()) {
                EventBus.error("User is unauthorized!");
            }
        });

        EventBus.$on(EventBusMessage.ACCESS_DENIED, async () => {
            this.$router.push("accessDenied");
        });

        GlobalMessageHelper.init();
    }
}
