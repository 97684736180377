import { MenuItem } from "@gis/common";
import i18n from '@/localization';

export default [
    MenuItem
        .withLink({ name: "userTasks.open" })
        .withIcon("far fa-tasks")
        .withLabel(i18n.t('strings.tasks').toString()),
    MenuItem
        .withLink({ name: "statics", params: { name: 'faq' } })
        .withIcon("far fa-question-circle")
        .withLabel(i18n.t('strings.faq').toString()),
    MenuItem
        .withLink({ name: "statics", params: { name: 'datenschutzhinweise' } })
        .withIcon("far fa-shield-check")
        .withLabel(i18n.t('strings.privacy').toString())
]