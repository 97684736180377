
import { Component, Prop, Vue } from "vue-property-decorator";
import { MenuItem } from "@gis/common";
import menuItems from "../../application/menuItems";
import { auth } from "@/utils/AuthService";

@Component
export default class SideBar extends Vue {
    mini = true;

    @Prop(Boolean)
    readonly drawer!: boolean;

    @Prop(Boolean)
    readonly mobile!: boolean;
    @Prop(Boolean)
    readonly value!: boolean;

    userLoggedIn = false;

    mounted(): void {
        this.mini = !this.mobile;
        auth().userIsLoggedIn()
            .then((result) => this.userLoggedIn = result);
    }
    get menuItems(): Array<MenuItem> {
        return menuItems;
    }

    login(): void {
        auth().login();
    }


    logout(): void {
        auth().logout();
    }
}
