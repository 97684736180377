import { Vue, Component } from 'vue-property-decorator';

@Component
export default class BaseMixins extends Vue {

    get breakpointMobile(): boolean {
        return this.$vuetify.breakpoint.smAndDown;
    }

    get breakpointDesktop(): boolean {
        return this.$vuetify.breakpoint.mdAndUp;
    }

    created(): void {
        document.title = process.env.VUE_APP_NAME;
    }
}