import i18n from '@/localization';
import router from '@/router'
import store from '@/store'
import { setMyAppEnv } from '@gis/common';

const appEnv = setMyAppEnv({
    baseApiUrl: process.env.VUE_APP_API_BASE_URL as string,
    i18n: i18n,
    store: store,
    router: router
});

export default appEnv;