import "@/application/myAppEnv"
import '@/scss/app.scss';

import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import BaseMixin from '@/mixins/BaseMixin';
import router from './router'
import i18n from './localization'
import vuetify from './plugins/vuetify';
import { auth } from  '@/utils/AuthService';
import { Configuration } from "./application/config";

Vue.mixin(BaseMixin);

Configuration.init(process.env.VUE_APP_CONFIG).then(() => {
    new Vue({
        router,
        store,
        i18n,
        vuetify,
        render: (h) => h(App),
        mounted() {
            if (!window.location.hash.includes('statics')) {
                auth().ensureUserIsLoggedIn();
            }
            else {
                console.log('Ignore login...')
            }
        }
    }).$mount("#app");
});
