import Vue from 'vue'
import Vuex from 'vuex'
import { GetterTree, MutationTree, ActionTree } from "vuex"

Vue.use(Vuex);

//How is the correct way to work with Vuex and typescript?
//https://stackoverflow.com/questions/53807294/how-is-the-correct-way-to-work-with-vuex-and-typescript

class State {
    loading = false;
    title = process.env.VUE_APP_NAME as string;
}

const getters = <GetterTree<State, State>>{
};

const mutations = <MutationTree<State>>{
    setLoading(state, value) {
        state.loading = value;
    }
};

const actions = <ActionTree<State, State>>{
};

export default new Vuex.Store({
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters
})