const strings = {
    "finished": "Finished",
    "in_progress": "In progress",
    "logout": "Log-out",
    "messages": {
        "bad_request": "Bad request",
        "cannot_reach_server": "Cannot reach server",
        "internal_server_error": "Internal server error",
        "not_found": "Not found",
        "unknown_error": "Unknown error",
        "validation_error": "Validation error"
    },
    "open": "Open",
    "status_equals": "Status: {status}",
    "tasks": "Tasks",
    "userTaskStatus": {
        "done": "Done",
        "open": "Open"
    }
}

export default strings;