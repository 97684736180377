import { Route } from '@gis/common';

const _route = Route
    .withPath('/')
    .withName('root')
    .onBeforeEnter((to, from, next) => {
        const _startUrl = process.env.VUE_APP_START_URI || '';
        next(`${process.env.VUE_APP_DEFAULT_LOCALE}/${_startUrl}`);
    });

export default _route;