

import { User } from 'oidc-client';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class UserAvatar extends Vue {
    @Prop({
        type: Object,
        required: true
    })
    readonly user!: User;

    get userTitle(): string {
        return this.user.profile?.name ?? "";
    }

    get userInitials(): string {
        const profile = this.user.profile;

        if (profile?.name_initials) {
            return this.user.profile.name_initials;
        } else if (profile?.given_name && profile?.family_name) {
            return profile.given_name.substring(0, 1) + profile.family_name.substring(0, 1);
        } else {
            return profile?.name?.substring(0, 2) ?? "";
        }
    }

    get hasPicture(): boolean {
        return !!this.user.profile?.picture;
    }

    get avatarPictureBase64(): string {
        var base64 = this.user.profile?.picture;
        if (base64) {
            return "data:image/png;base64, " + base64;
        }
        return "";
    }

}
