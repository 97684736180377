import './fontawesome/css/all.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VuetifyFloatingMessage from '@gis/vuetify-floating-message';

Vue.use(Vuetify);

import de from 'vuetify/src/locale/de';
import en from 'vuetify/src/locale/en';

import i18n from '../localization';

const vuetify = new Vuetify({
    icons: {
        iconfont: 'fa'
    },
    lang: {
        locales: { de, en },
        current: i18n.locale
    },
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: process.env.VUE_APP_PRIMARY_COLOR,
                secondary: process.env.VUE_APP_SECONDARY_COLOR,
                secondary_gradient_end: process.env.VUE_APP_SECONDARY_COLOR_GRADIENT_END,
                background: process.env.VUE_APP_BACKGROUND_COLOR,
                foreground: process.env.VUE_APP_FOREGROUND_COLOR,
            },
            dark: {
                primary: process.env.VUE_APP_PRIMARY_COLOR,
                secondary: process.env.VUE_APP_SECONDARY_COLOR,
                secondary_gradient_end: process.env.VUE_APP_SECONDARY_COLOR_GRADIENT_END,
                background: process.env.VUE_APP_BACKGROUND_COLOR,
                foreground: process.env.VUE_APP_FOREGROUND_COLOR,
            }
        }
    }
});

Vue.use(VuetifyFloatingMessage, { vuetify });
export default vuetify;