import { UserManagerSettings } from "oidc-client";
import { AuthServiceBase } from "@gis/common";
import { Configuration } from "@/application/config";



//https://docs.secureappfabric.de/display/SECDEVDOC/5.+Authentifizierung+mit+dem+.secure-IdentityServer
class AuthService extends AuthServiceBase {
    configure(): UserManagerSettings {
        const settings = this.createDefaultUserManagerSettings(
            Configuration.identity.authority,
            Configuration.identity.client_id,
            'openid profile roles'
        );
        return settings;
    }

    protected setDefaults(bindEvents: boolean): void {
        super.setDefaults(bindEvents);
    }
}

function auth(): AuthService {
    return new AuthService();
}

export { auth };