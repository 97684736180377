
import { Component, Vue } from "vue-property-decorator";
import { User } from "oidc-client";
import Loader from "@/components/globals/Loader.vue";
import AppBar from "@/components/globals/AppBar.vue";
import SideBar from "@/components/globals/SideBar.vue";
import { GlobalMessageHelper } from "@gis/common";
import { auth } from "@/utils/AuthService";

@Component({
    components: { AppBar, Loader, SideBar },
})
export default class Master extends Vue {
    currentUser: User | null = null;

    sidebarToggler = false;
    pageTitle = "";

    get loading(): boolean {
        return this.$store.state.loading;
    }

    get pageTitleComputed(): string {
        const title = this.$store.state.title;
        return title;
    }

    mounted(): void {
        //Is anyone logged in yet?
        auth().getUser()
            .then((user) => {
                this.currentUser = user;
            })
            .catch((e) => {
                console.error(e);
            });

        GlobalMessageHelper.init();
    }
}
