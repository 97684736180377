import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router';
import root from './root';
import rootLocalized from './rootLocalized';
import userTasks from './userTasks';
import statics from './statics';
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    root.toRouteConfig(),
    rootLocalized
        .withChildren([
            userTasks.toRouteConfig(),
            statics,
        ]).toRouteConfig()
];

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes
})

export default router