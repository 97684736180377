import { Route } from '@gis/common';

export default Route
    .withPath("tasks", () => { return import('@/views/userTasks/UserTasks.vue'); })
    .withChildren([
        Route.withPath("open", () => { return import('@/views/userTasks/OpenUserTasks.vue') })
            .withName("userTasks.open"),
        Route.withPath("ongoing", () => { return import('@/views/userTasks/OngoingUserTasks.vue') })
            .withName("userTasks.ongoing"),
        Route.withPath("finished", () => { return import('@/views/userTasks/FinishedUserTasks.vue') })
            .withName("userTasks.finished"),
    ]);