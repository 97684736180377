import { loadConfig } from "@gis/easy-config";
import Identity from "./Identity";

class Configuration {
    static records: Record<string, unknown>;

    static get midPath(): string | null {
        return Configuration.getSection<string>("midPath") ?? process.env.VUE_APP_ORIGIN_MID_PATH ?? null;
    }

    static get identity(): Identity {
      return (
            Configuration.getSection<Identity>("identity") ??
            ({
                authority: process.env.VUE_APP_IDENTITY_SERVER_URL,
                client_id: process.env.VUE_APP_IDENTITY_SERVER_CLIENT_ID,
            } as Identity)
        );
    }

    static get privacyLink(): string | null {
        return Configuration.getSection<string>("privacyLink") ?? process.env.VUE_APP_PRIVACY_LINK ?? null;
    }

    static getSection<T>(section: string): T | null {
        if (!Configuration.records) return null;
        const value = Configuration.records[section];
        if (!value) return null;
        return value as T;
    }

    static async init(configName = "config"): Promise<void> {
      Configuration.records = await loadConfig(configName);
        return;
    }
}

export { Configuration };
